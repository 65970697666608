import React, { useEffect, useState } from 'react'
import PubMedIconComponent from 'assets/svgs/pubmed.svg'
import AdaptiveDate from 'components/AdaptiveDate/AdaptiveDate'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'
import { useNotifications } from 'components/Notification/NotificationsContext'
import { PublicationSource } from 'types/amplify/sharedTypes/publication/enums/publicationSource'
import { getFile } from 'services/publication'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  style?: React.CSSProperties
  id?: string
  displayAs: PublicationSource
  date: Date
  pubmedId: string
  fileName?: string
}

const InputDataTile = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const cn = `${props.className || ''}`
  const pubmedIdOrFilename =
    props.displayAs === PublicationSource.Pubmed
      ? props.pubmedId
      : props.fileName

  const { getNotificationsRef } = useNotifications()

  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)
  const [fileURL, setFileURL] = useState<string>()

  useEffect(() => {
    void (async function () {
      if (!props.fileName) return
      try {
        setIsLoadingFile(true)
        const file = await getFile(props.fileName)
        setFileURL(file)
        setIsLoadingFile(false)
        console.log('file', file)
      } catch (err) {
        const errorMessage = 'Failed loading publication.'
        console.error(errorMessage, err)
        setIsLoadingFile(false)
        getNotificationsRef().addNotification({
          text: errorMessage,
          isError: true,
        })
      }
    })()
  }, [getNotificationsRef, props.fileName])

  const TagName = isLoadingFile ? 'div' : 'a'

  const PubMedIcon = PubMedIconComponent as any

  return (
    <div ref={ref} className={cn}>
      <TagName
        href={fileURL || `https://pubmed.ncbi.nlm.nih.gov/${props.pubmedId}/`}
        target="_blank"
        rel="noreferrer noopener"
        className="group rounded-lg overflow-hidden h-full flex flex-col"
      >
        <div className="bg-sensitive-grey-darkest h-40 flex justify-center items-center">
          {props.displayAs === PublicationSource.Pubmed ? (
            <div className="inline-block h-16">
              <span className="block font-black text-rich-blue text-xs pt-2 -mb-1">
                Read this on
              </span>
              <PubMedIcon className="w-24 h-10" />
            </div>
          ) : isLoadingFile ? (
            <RoundLoadingIndicator />
          ) : (
            <object
              className="w-full h-full inline-flex justify-center items-center"
              type="application/octet-stream"
              data={fileURL + '#toolbar=0'}
            >
              <span className="text-vibrant-cyan group-hover:underline">
                Download file
              </span>
            </object>
          )}
        </div>
        <div className="bg-white px-4 py-3 flex-grow">
          <div className="list-item__date-and-source flex w-full flex-wrap justify-between">
            <span className="list-item__date text-right flex-shrink-0 mb-1 truncate">
              <AdaptiveDate date={props.date} />
            </span>
            <span
              title={pubmedIdOrFilename}
              style={{ maxWidth: '30ch' }}
              className="list-item__source text-rich-black-lightest truncate flex-shrink"
            >
              {props.displayAs === PublicationSource.Pubmed ? 'PMID ' : ''}
              {pubmedIdOrFilename}
            </span>
          </div>
        </div>
      </TagName>
    </div>
  )
})

export default InputDataTile
