import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { SearchBar } from '@liquid-design/liquid-design-react'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'
import CrossIconComponent from 'assets/svgs/cross.svg'

import './GenesSearchInput.css'

interface Props {
  disabled?: boolean
  isLoading?: boolean
  className?: string
  style?: React.CSSProperties
  id?: string
  onSubmit: (query: string) => void
  value: string
  'data-test'?: string
}

const GenesSearchInput: React.FC<Props> = ({
  disabled,
  isLoading,
  className,
  style,
  id,
  onSubmit,
  value,
  'data-test': dataTest,
}) => {
  const searchInput = useRef<HTMLInputElement>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')

  // HACK: LDS React currently doesn't offer a value prop on the SearchBar component
  const setSearchValue = (v: string) => {
    ;(searchInput?.current as any).__wrappedInstance.setInputValue(v)
  }

  useEffect(() => {
    setSearchValue(value)
    setSearchQuery(value)
  }, [value])

  const clearSearch = () => {
    setSearchValue('')
    setSearchQuery('')
    onSubmit('')
  }

  const cn = `${className || ''} inline-flex relative`

  const CrossIcon = CrossIconComponent as any

  return (
    <div data-test={dataTest} className={cn}>
      <SearchBar
        ref={searchInput}
        disabled={disabled}
        onSubmit={(query: string) => {
          onSubmit(query)
        }}
        options={[]}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setSearchQuery(event.target.value)
        }
      />
      {isLoading ? (
        <RoundLoadingIndicator className="absolute right-0 align-transform-y p-3" />
      ) : searchQuery ? (
        <button
          disabled={disabled}
          type="reset"
          onClick={clearSearch}
          className={`${
            disabled ? 'opacity-50 cursor-default' : ''
          } absolute right-0 align-transform-y p-3`}
        >
          <CrossIcon className="stroke-current text-rich-blue-darkest" />
          <span className="sr-only">Clear search</span>
        </button>
      ) : (
        ''
      )}
    </div>
  )
}

export default GenesSearchInput
