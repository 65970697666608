import React, { useEffect, useState } from 'react'
import CopyIconComponent from 'assets/svgs/copy.svg'
import CheckIconComponent from 'assets/svgs/check.svg'

interface Props {
  ref?: React.RefObject<HTMLButtonElement>
  className?: string
  style?: React.CSSProperties
  id?: string
  textToCopy: string
}

const CopyToClipboard = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const cn = `${
      props.className || ''
    } p-2 inline-flex justify-center items-center`

    const [copyTimeout, setCopyTimeout] = useState<number | undefined>()

    useEffect(() => {
      return () => {
        window.clearTimeout(copyTimeout)
      }
    }, [copyTimeout])

    const clearCopyTimeout = () => {
      window.clearTimeout(copyTimeout)
      setCopyTimeout(undefined)
    }

    const CopyIcon = CopyIconComponent as any
    const CheckIcon = CheckIconComponent as any

    return (
      <button
        ref={ref}
        className={cn}
        disabled={!!copyTimeout}
        onClick={() => {
          navigator.clipboard.writeText(props.textToCopy)
          const timeoutID = window.setTimeout(clearCopyTimeout, 1000)
          setCopyTimeout(timeoutID)
        }}
      >
        {copyTimeout ? (
          <>
            <span className="sr-only">Copied to clipboard</span>
            <CheckIcon className="stroke-current text-vibrant-cyan w-3 h-3" />
          </>
        ) : (
          <>
            <span className="sr-only">Copy to clipboard</span>
            <CopyIcon className="stroke-current text-vibrant-cyan w-5 -m-1" />
          </>
        )}
      </button>
    )
  }
)

export default CopyToClipboard
