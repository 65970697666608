import React from 'react'
import { Router } from '@reach/router'
import PublicationDetails from 'components/Publication/PublicationDetails'

const Publication = () => {
  return (
    <Router basepath="/publication">
      <PublicationDetails path="/:id" />
    </Router>
  )
}
export default Publication
