import React from 'react'
import { Link } from 'gatsby'
import { Button } from '@liquid-design/liquid-design-react'
import BackIconComponent from 'assets/svgs/back.svg'
import DeleteIconComponent from 'assets/svgs/trash.svg'
import ReportIconComponent from 'assets/svgs/forbidden.svg'
import DownloadIconComponent from 'assets/svgs/download.svg'

import './Toolbar.css'
import RoundLoadingIndicator from '../../../components/RoundLoadingIndicator/RoundLoadingIndicator'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  style?: React.CSSProperties
  id?: string
  isDeleting?: boolean
  isExporting?: boolean
  isExportingAll?: boolean
  onDelete: () => void
  onReport: () => void
  onExportAll: () => void
}

const Toolbar = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const cn = `toolbar flex items-start justify-end flex-wrap -mx-2 mb-2 ${
    props.className || ''
  }`

  const BackIcon = BackIconComponent as any
  const DeleteIcon = DeleteIconComponent as any
  const ReportIcon = ReportIconComponent as any
  const DownloadIcon = DownloadIconComponent as any

  return (
    <div ref={ref} className={cn}>
      <Link className="md:mr-auto w-full lg:w-auto mb-8" to={'/database/'}>
        <Button tabIndex="-1" size="small" className="toolbar__button">
          <BackIcon className="inline-block mr-4 -mt-1" />
          Go Back
        </Button>
      </Link>

      <Button
        disabled={props.isDeleting}
        size="small"
        className="toolbar__button toolbar__button--delete mb-4 mx-2"
        onClick={props.onDelete}
      >
        <DeleteIcon className="inline-block mr-4 h-4 -mt-1 stroke-current text-rich-red-dark" />
        {props.isDeleting ? (
          <>
            Deleting{' '}
            <RoundLoadingIndicator
              style={{ transform: 'translateY(0.28rem)' }}
              className="inline-flex ml-2 -mt-8"
            />
          </>
        ) : (
          'Delete'
        )}
      </Button>

      <Button
        size="small"
        className="toolbar__button toolbar__button--report mb-4 mx-2"
        onClick={props.onReport}
      >
        <ReportIcon className="inline-block mr-4 h-4 -mt-1" />
        Report Error
      </Button>

      <button
        disabled={props.isExporting || props.isExportingAll}
        className={`toolbar__button mb-4 mx-2 flex items-center bg-rich-blue hover:bg-rich-blue-dark focus:bg-rich-blue-dark active:bg-rich-blue-darker px-6 rounded-md font-black text-white ${
          props.isExporting || props.isExportingAll
            ? 'opacity-50 cursor-default'
            : ''
        }`}
        onClick={props.onExportAll}
      >
        {props.isExportingAll ? (
          <>
            <RoundLoadingIndicator className="mr-3" />
            Exporting genes
          </>
        ) : (
          <>
            <DownloadIcon className="inline-block fill-current text-white mr-3" />
            Export all genes as .csv
          </>
        )}
      </button>
    </div>
  )
})

export default Toolbar
