import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { Storage } from 'aws-amplify'
import { Theme } from '@liquid-design/liquid-design-react'
import {
  deletePublications,
  getPublication,
  deleteGenes,
  exportGenes,
  getGenes,
} from 'services/publication'
import LayoutDefault from 'layouts/LayoutDefault/LayoutDefault'
import TopBar from 'components/TopBar/TopBar'
import Sidebar from 'components/Sidebar/Sidebar'
import Container from 'components/Container/Container'
import CursorPagination from 'components/CursorPagination/CursorPagination'
import Toaster from 'components/Toaster/Toaster'
import DeleteExportToolbar from 'components/DeleteExportToolbar/DeleteExportToolbar'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import { useNotifications } from 'components/Notification/NotificationsContext'
import LogoComponent from 'assets/svgs/logo.svg'
import { Publication } from 'types/amplify/sharedTypes/publication/interfaces/publication.interface'
import { PublicationSource } from 'types/amplify/sharedTypes/publication/enums/publicationSource'
import { SortOption } from 'types/GeneList'
import { Gene } from 'types/amplify/sharedTypes/gene/interfaces/gene.interface'
import NoPublication from 'components/Publication/NoPublication/NoPublication'
import GeneListItem from 'components/Publication/GeneListItem/GeneListItem'
import GenesSearchInput from 'components/Publication/GenesSearchInput/GenesSearchInput'
import Toolbar from 'components/Publication/Toolbar/Toolbar'
import InputDataTile from 'components/Publication/InputDataTile/InputDataTile'
import { useStorePublication } from 'store/publication'
import { useStoreConfirmation } from 'store/confirmation'
import usePrevious from 'hooks/usePrevious'
import { useUser } from '../../UserContext'
import GeneListHeader from 'components/Publication/GeneListHeader/GeneListHeader'

const PAGINATION_LIMIT = 10

interface Props {
  path: string
  id?: string
}

export default function PublicationDetails(props: Props) {
  const { getNotificationsRef } = useNotifications()
  const location = useLocation()

  const { user } = useUser()

  const publicationId = props.id || ''
  const [isLoadingPublication, setIsLoadingPublication] = useState<boolean>(
    true
  )
  const [hasFetchedData, setHasFetchedData] = useState<boolean>(false)
  const [hasPublication, setHasPublication] = useState<boolean>(false)
  const [hasGenes, setHasGenes] = useState<boolean>(false)

  const [isLoadingGenes, setIsLoadingGenes] = useState<boolean>(true)
  const [isLoadingPrevPage, setIsLoadingPrevPage] = useState<boolean>(false)
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false)
  const [paginationTokens, setPaginationTokens] = useState<string[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>(
    SortOption.bySymbolAZ
  )
  const [submittedSearchQuery, setSubmittedSearchQuery] = useState<string>('')
  const [publication, setPublication] = useState<Publication | null>(null)
  const [genes, setGenes] = useState<Gene[]>([])
  const [genesCount, setGenesCount] = useState<number>(0)
  const [genesSearchCount, setGenesSearchCount] = useState<number>(0)
  const [isDeletingGenes, setIsDeletingGenes] = useState<boolean>(false)
  const [isExportingGenes, setIsExportingGenes] = useState<boolean>(false)
  const [isExportingAllGenes, setIsExportingAllGenes] = useState<boolean>(false)
  const [disabledGeneIds, setDisabledGeneIds] = useState<{
    [geneId: string]: boolean
  }>({})
  const [selectedGenes, setSelectedGenes] = useState<Gene[]>([])

  const isDeletingPublication = useStorePublication(
    (state) => state.isDeletingPublications
  )
  const setIsDeletingPublication = useStorePublication(
    (state) => state.setIsDeletingPublications
  )

  const prevPublication = usePrevious({ publication })
  const prevSubmittedSearchQuery = usePrevious({ submittedSearchQuery })
  const prevPageIndex = usePrevious({ currentPageIndex })
  const prevIsDeletingGenes = usePrevious({ isDeletingGenes })
  const prevSelectedSortOption = usePrevious({ selectedSortOption })

  const updateHistorySearchGenes = (qg: string) => {
    const url = new URL(window.location as any)
    if (qg) {
      url.searchParams.set('qg', qg)
    } else {
      url.searchParams.delete('qg')
    }
    navigate(url.toString().substring(url.origin.length))
  }

  // Populate search input field on load.
  React.useEffect(() => {
    const search = location.search
    const params = new URLSearchParams(search)
    const qg = params.get('qg')
    const pi = params.get('pi')
    setSubmittedSearchQuery(qg || '')
    setCurrentPageIndex(parseInt(pi || '', 10) || 0)
    setSearchQuery(qg || '')
  }, [setSubmittedSearchQuery, location.search])

  // Reset page index to 0 for new searches.
  useEffect(() => {
    if (submittedSearchQuery === prevSubmittedSearchQuery?.submittedSearchQuery)
      return
    setCurrentPageIndex(0)
  }, [submittedSearchQuery, prevSubmittedSearchQuery])

  // Initiate loading of genes on new search or page submission.
  useEffect(() => {
    if (!user) return

    // Prevent re-loading genes on initial load.
    if (!publication || publication.id !== prevPublication?.publication?.id) {
      return
    }

    // Prevent re-loading genes when deleting genes.
    if (isDeletingGenes) {
      return
    }

    // Force re-loading on completed gene deletion.
    if (!prevIsDeletingGenes?.isDeletingGenes) {
      // Prevent re-loading genes if pagination, sort option and submitted search query haven't changed.
      if (
        selectedSortOption === prevSelectedSortOption?.selectedSortOption &&
        currentPageIndex === prevPageIndex?.currentPageIndex &&
        submittedSearchQuery === prevSubmittedSearchQuery?.submittedSearchQuery
      ) {
        return
      }
    }

    setIsLoadingGenes(true)
    setGenesSearchCount(0)
    ;(async () => {
      try {
        const genes = await getGenes({
          id: publicationId,
          sort: selectedSortOption,
          paginationToken: paginationTokens[currentPageIndex - 1] || null,
          limit: PAGINATION_LIMIT,
          query: submittedSearchQuery || null,
        })

        // Handle case, when although there is a pagination token, there are no genes on next page,
        // by showing a warning message and removing the next page button.
        if (
          !genes?.items.length &&
          currentPageIndex !== 0 &&
          currentPageIndex !== prevPageIndex?.currentPageIndex
        ) {
          getNotificationsRef().addNotification({
            text: 'There are no more genes.',
            isInfo: true,
          })
          setPaginationTokens(paginationTokens.slice(0, -1))
        } else {
          const tokens = [...paginationTokens]
          tokens[currentPageIndex] = genes?.nextToken || ''
          setPaginationTokens(tokens)
          setGenes(genes?.items || [])
          if (submittedSearchQuery) {
            setGenesSearchCount(genes?.items.length || 0)
          }
        }
      } catch (err) {
        const errorMessage = 'Failed loading genes.'
        console.error(errorMessage, err)
        getNotificationsRef().addNotification({
          text: errorMessage,
          isError: true,
        })
      }
      setIsLoadingGenes(false)
      setIsLoadingPrevPage(false)
      setIsLoadingNextPage(false)
    })()
  }, [
    user,
    selectedSortOption,
    isDeletingGenes,
    prevIsDeletingGenes,
    getNotificationsRef,
    submittedSearchQuery,
    prevSubmittedSearchQuery,
    prevPageIndex,
    prevSelectedSortOption,
    currentPageIndex,
    paginationTokens,
    publicationId,
    prevPublication,
    publication,
  ])

  // Load publication and genes.
  useEffect(() => {
    if (!user) return

    if (hasFetchedData) return

    // Load publication.
    setHasFetchedData(true)
    ;(async () => {
      try {
        const publication = await getPublication({
          id: publicationId,
        })
        if (!publication) {
          getNotificationsRef().addNotification({
            text: 'Publication not found.',
            isError: true,
          })
        } else {
          setPublication(publication)
          setHasPublication(true)
          setGenesCount(publication?.genesCount || 0)
        }
      } catch (err) {
        const errorMessage = 'Failed loading publication.'
        console.error(errorMessage, err)
        getNotificationsRef().addNotification({
          text: errorMessage,
          isError: true,
        })
      }
      setIsLoadingPublication(false)
    })()

    // Load genes.
    const search = location.search
    const params = new URLSearchParams(search)
    const query = params.get('qg')
    ;(async () => {
      try {
        const genes = await getGenes({
          id: publicationId,
          sort: SortOption.bySymbolAZ,
          paginationToken: null,
          limit: PAGINATION_LIMIT,
          query: query || null,
        })
        setPaginationTokens([genes?.nextToken || ''])
        setGenes(genes?.items || [])
        setHasGenes(true)
        if (query) {
          setGenesSearchCount(genes?.items.length || 0)
        }
      } catch (err) {
        const errorMessage = 'Failed loading genes.'
        console.error(errorMessage, err)
        getNotificationsRef().addNotification({
          text: errorMessage,
          isError: true,
        })
      }
      setIsLoadingGenes(false)
    })()
  }, [
    user,
    getNotificationsRef,
    publicationId,
    location.search,
    hasFetchedData,
  ])

  const onDeletePublicationConfirm = async () => {
    let result
    try {
      result = await deletePublications([publicationId])
    } catch (err) {
      console.error('Failed deleting publications.', err)
    }

    if (!result || result.failedPublicationIds.length) {
      getNotificationsRef().addNotification({
        text: 'Failed deleting publication.',
        isError: true,
      })
      setIsDeletingPublication(false)
      return
    }

    getNotificationsRef().addNotification({
      text: 'Publication has been deleted.',
      color:
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue('--vibrant-green-dark') || undefined,
    })
    setIsDeletingPublication(false)

    // Redirect to home.
    navigate('/database/')
  }

  const onDeletePublication = async () => {
    setIsDeletingPublication(true)
    useStoreConfirmation.setState({
      isConfirmationModalOpen: true,
      headline: 'Are you sure you want to delete this publication?',
      onConfirm: () => {
        onDeletePublicationConfirm()
      },
      onCancel: () => {
        setIsDeletingPublication(false)
      },
    })
  }

  const disableGenes = (geneIds: string[]) => {
    const map = { ...disabledGeneIds }
    for (const id of geneIds) {
      map[id] = true
    }
    setDisabledGeneIds(map)
  }
  const enableGenes = (geneIds: string[]) => {
    const map = { ...disabledGeneIds }
    for (const id of geneIds) {
      delete map[id]
    }
    setDisabledGeneIds(map)
  }

  const onDeleteGenesConfirm = async (geneIds: string[]) => {
    // disable publication tile and show loader
    disableGenes(geneIds)

    const failedGeneIds: string[] = []
    const succeededGeneIds: string[] = []
    try {
      const result = await deleteGenes({ publicationId, geneIds })
      failedGeneIds.push(...result.failedGeneIds)
      succeededGeneIds.push(...result.succeededGeneIds)
      setSelectedGenes(
        selectedGenes.filter((gene) => !succeededGeneIds.includes(gene.id))
      )
    } catch (err) {
      console.error('Failed deleting genes.', err)
      failedGeneIds.push(...geneIds)
    }

    if (failedGeneIds.length) {
      let errorMessage
      if (geneIds.length === 1 || failedGeneIds.length === 1) {
        errorMessage = `Failed deleting gene with id: ${failedGeneIds[0]}`
      } else {
        errorMessage = `Failed deleting ${failedGeneIds.length} of ${geneIds.length} genes.`
      }
      getNotificationsRef().addNotification({
        text: errorMessage,
        isError: true,
      })
    } else {
      let successMessage
      if (succeededGeneIds.length === 1) {
        successMessage = `Gene has been deleted.`
      } else {
        successMessage = `${succeededGeneIds.length} genes have been deleted.`
      }
      getNotificationsRef().addNotification({
        text: successMessage,
        color:
          window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--vibrant-green-dark') || undefined,
      })
    }

    enableGenes(failedGeneIds)
    setPaginationTokens([])
    setCurrentPageIndex(0)
    setIsDeletingGenes(false)
  }

  const onDeleteGenes = async (geneIds: string[]) => {
    setIsDeletingGenes(true)
    useStoreConfirmation.setState({
      isConfirmationModalOpen: true,
      headline: `Are you sure you want to delete ${
        geneIds.length === 1 ? 'this gene' : 'all selected genes'
      }?`,
      onConfirm: () => {
        onDeleteGenesConfirm(geneIds)
      },
      onCancel: () => {
        setIsDeletingGenes(false)
      },
    })
  }

  const onExportGenes = async (geneIds: string[]) => {
    const isExportingAll = !geneIds.length
    let failedExportingAll = false
    if (isExportingAll) {
      setIsExportingAllGenes(true)
    } else {
      setIsExportingGenes(true)
    }

    const failedGeneIds: string[] = []
    let key = ''
    try {
      let result
      if (geneIds.length) {
        result = await exportGenes({ geneIds })
      } else {
        result = await exportGenes({ publicationId })
      }
      failedGeneIds.push(...result.failedGeneIds)
      key = result.key
    } catch (err) {
      console.error('Failed exporting genes.', err)
      if (isExportingAll) {
        failedExportingAll = true
      } else {
        failedGeneIds.push(...geneIds)
      }
    }

    if (failedGeneIds.length || failedExportingAll) {
      let errorMessage
      if (geneIds.length === 1 || failedGeneIds.length === 1) {
        errorMessage = `Failed exporting gene with id: ${failedGeneIds[0]}`
      } else {
        errorMessage = `Failed exporting ${failedGeneIds.length}${
          geneIds.length ? ' of ' + geneIds.length : ''
        } genes.`
      }
      getNotificationsRef().addNotification({
        text: errorMessage,
        isError: !key,
        isInfo: !!key,
      })
    } else {
      const successMessage = `Gene${
        geneIds.length === 1 ? ' has' : 's have'
      } been exported.`
      getNotificationsRef().addNotification({
        text: successMessage,
        color:
          window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--vibrant-green-dark') || undefined,
      })
    }

    if (key) {
      const link = await Storage.get(key.replace('dl/', ''), {
        level: 'public',
        cacheControl: 'no-cache',
        download: false,
        contentType: 'application/pdf',
        contentDisposition: `attachment; filename="exported_publications.csv"`,
        customPrefix: {
          public: 'dl/',
        },
      })

      const win = window.open(link as string, '_blank', 'noreferrer')
      win?.focus()
    }

    if (isExportingAll) {
      setIsExportingAllGenes(false)
    } else {
      setIsExportingGenes(false)
    }
  }

  const Logo = LogoComponent as any

  return (
    <>
      <LayoutDefault
        data-test="publication"
        topBar={<TopBar />}
        sidebar={<Sidebar />}
      >
        {isLoadingPublication && (
          <Container className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center text-center w-full">
              <Logo className="mb-4" />
              <div className="py-2 px-6">
                <LoadingIndicator className="text-rich-blue font-bold">
                  Loading publication
                </LoadingIndicator>
              </div>
            </div>
          </Container>
        )}

        {!isLoadingPublication && (
          <>
            {!hasPublication && (
              <Container className="flex flex-col items-center justify-center">
                <NoPublication />
              </Container>
            )}

            {hasPublication && (
              <>
                <Container style={{ paddingTop: 0 }}>
                  <div className="pt-6" style={{ height: '8rem' }}>
                    <Toolbar
                      isDeleting={isDeletingPublication}
                      onDelete={() => {
                        onDeletePublication()
                      }}
                      onReport={() => {
                        const receiver = 'support@knoragene.com'
                        const paperDescriptor = `${
                          publication?.source === PublicationSource.Pubmed
                            ? 'PMID'
                            : 'filename'
                        }: ${publication?.pubmedIdOrFilename.replace(
                          '__gc__',
                          ''
                        )}`
                        const subject = `Error with Paper (${paperDescriptor})`
                        let body = `Hello,\nan error was seen with the gene extraction for paper with ${paperDescriptor} (publicationId: ${publicationId}) at ${new Date().toISOString()}.\n\n`
                        body += `Additional information:\n`
                        body += `User agent: ${navigator.userAgent}\n\n`
                        body += '(Please add more information here)'
                        const win = window.open(
                          `mailto:${receiver}?subject=${encodeURIComponent(
                            subject
                          )}&body=${encodeURIComponent(body)}`,
                          '_blank',
                          'noreferrer'
                        )
                        win?.focus()
                      }}
                      isExporting={isExportingGenes}
                      isExportingAll={isExportingAllGenes}
                      onExportAll={() => onExportGenes([])}
                    />

                    <h1 className="mb-4 block font-black text-2xl">
                      {publication?.title}
                    </h1>

                    <div className="mb-8">
                      {publication?.source === PublicationSource.Pdf ? (
                        <>File: {publication?.pubmedIdOrFilename}</>
                      ) : (
                        <>
                          PMID{' '}
                          <a
                            target="_blank"
                            rel="noreferrer noopener"
                            className="underline hover:no-underline font-black text-vibrant-cyan"
                            href={`https://pubmed.ncbi.nlm.nih.gov/${publication?.pubmedId}/`}
                          >
                            {publication?.pubmedIdOrFilename}
                          </a>
                        </>
                      )}
                    </div>

                    {!hasGenes ? (
                      <div className="flex flex-col items-center text-center pt-6 pb-10">
                        <Logo className="mb-4" />
                        <div className="py-2 px-6">
                          <LoadingIndicator className="text-rich-blue font-bold">
                            Loading genes
                          </LoadingIndicator>
                        </div>
                      </div>
                    ) : !genes?.length && !submittedSearchQuery ? (
                      <div className="flex justify-between items-baseline mb-8">
                        <h2 className="text-rich-blue font-black">
                          No genes extracted.
                        </h2>
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-between items-baseline mb-6">
                          {submittedSearchQuery ? (
                            <h2 className="text-rich-blue font-black">
                              {genesSearchCount > PAGINATION_LIMIT - 1
                                ? `> ${PAGINATION_LIMIT - 1}`
                                : genesSearchCount}{' '}
                              {genesSearchCount === 1
                                ? 'search result'
                                : 'search results'}{' '}
                              in extracted genes.
                            </h2>
                          ) : (
                            <h2 className="text-rich-blue font-black">
                              {Number(genesCount).toLocaleString('en-EN')}{' '}
                              {genesCount === 1 ? 'gene' : 'genes'} extracted.
                            </h2>
                          )}

                          <GenesSearchInput
                            data-test="genes-search-input"
                            isLoading={
                              !!(isLoadingGenes && submittedSearchQuery)
                            }
                            disabled={
                              isLoadingGenes ||
                              isDeletingPublication ||
                              isDeletingGenes
                            }
                            value={searchQuery}
                            className="publication__gene-search"
                            onSubmit={(query: string) => {
                              updateHistorySearchGenes(query)
                            }}
                          />
                        </div>

                        {genesSearchCount !== 0 ? (
                          <GeneListHeader
                            className="mb-4"
                            selectedSortOption={selectedSortOption}
                            onChange={(sortOption) => {
                              setPaginationTokens([])
                              setCurrentPageIndex(0)
                              setSelectedSortOption(sortOption)
                            }}
                            onSelectionChange={(isSelected) => {
                              const without = selectedGenes.filter(
                                (g) => !genes.find((gene) => gene.id === g.id)
                              )
                              if (isSelected) {
                                setSelectedGenes([...without, ...genes])
                              } else {
                                setSelectedGenes([...without])
                              }
                            }}
                            isSelected={
                              genes.filter((g) =>
                                selectedGenes.find((gene) => gene.id === g.id)
                              ).length === genes.length
                            }
                          />
                        ) : (
                          ''
                        )}

                        <Theme themeName="vibrantCyan">
                          <ul
                            data-test="genes-list"
                            className={`${
                              isLoadingGenes || isDeletingPublication
                                ? 'opacity-50 pointer-events-none'
                                : ''
                            } flex flex-col mb-6`}
                          >
                            {genes.map((gene, index) =>
                              gene ? (
                                <GeneListItem
                                  data-test={`genes-list-item-${gene.symbol}`}
                                  key={index}
                                  gene={gene}
                                  disabled={!!disabledGeneIds[gene.id]}
                                  loading={!!disabledGeneIds[gene.id]}
                                  onSelectionChange={(isSelected) => {
                                    const without = selectedGenes.filter(
                                      (g) => g.id !== gene.id
                                    )
                                    setSelectedGenes(
                                      isSelected ? [...without, gene] : without
                                    )
                                  }}
                                  isSelected={
                                    !!selectedGenes.find(
                                      (selected) => selected.id === gene.id
                                    )
                                  }
                                />
                              ) : (
                                ''
                              )
                            )}
                          </ul>
                        </Theme>

                        <CursorPagination
                          className="mb-12"
                          onPrevClick={() => {
                            setIsLoadingPrevPage(true)
                            const pageIndex = currentPageIndex - 1
                            setCurrentPageIndex(pageIndex)
                          }}
                          onNextClick={() => {
                            setIsLoadingNextPage(true)
                            const pageIndex = currentPageIndex + 1
                            setCurrentPageIndex(pageIndex)
                          }}
                          isPrevHidden={
                            !isLoadingGenes && currentPageIndex === 0
                          }
                          isPrevDisabled={
                            isLoadingGenes ||
                            isDeletingPublication ||
                            isDeletingGenes
                          }
                          isPrevLoading={isLoadingPrevPage}
                          isNextHidden={
                            !isLoadingGenes &&
                            !paginationTokens[currentPageIndex]
                          }
                          isNextDisabled={
                            isLoadingGenes ||
                            isDeletingPublication ||
                            isDeletingGenes
                          }
                          isNextLoading={isLoadingNextPage}
                        />
                      </>
                    )}

                    {publication && (
                      <>
                        <h2 className="uppercase text-rich-black-lightest mb-4">
                          Input Data
                        </h2>
                        <div className="flex -mx-3 mb-10">
                          {publication?.source === PublicationSource.Pdf && (
                            <InputDataTile
                              className="px-3 w-1/2 lg:w-1/3"
                              displayAs={PublicationSource.Pdf}
                              date={new Date(publication.createdAt || '')}
                              pubmedId={publication.pubmedId}
                              fileName={publication.pubmedIdOrFilename || ''}
                            />
                          )}
                          <InputDataTile
                            className="px-3 w-1/2 lg:w-1/3"
                            displayAs={PublicationSource.Pubmed}
                            date={new Date(publication.createdAt || '')}
                            pubmedId={publication.pubmedId}
                          />
                        </div>
                      </>
                    )}

                    <div className="text-sm text-center text-rich-black-lighter pb-8">
                      Source: National Library of Medicine.
                    </div>
                  </div>
                </Container>

                <Toaster
                  className="absolute"
                  isToasted={!!selectedGenes.length}
                  toastClassName="min-w-2/3 md:min-w-auto"
                >
                  <DeleteExportToolbar
                    onDelete={() => {
                      onDeleteGenes(selectedGenes.map((gene) => gene.id))
                    }}
                    onExport={() => {
                      onExportGenes(selectedGenes.map((gene) => gene.id))
                    }}
                    isDeleting={isDeletingGenes}
                    isExporting={isExportingGenes}
                    isExportingAll={isExportingAllGenes}
                    totalSelected={selectedGenes.length}
                    subject="gene"
                  />
                </Toaster>
              </>
            )}
          </>
        )}
      </LayoutDefault>
    </>
  )
}
