import React from 'react'
import { Accordion, Button, Theme } from '@liquid-design/liquid-design-react'
import RoundCheckbox from 'components/RoundCheckbox/RoundCheckbox'
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'
import { Gene } from 'types/amplify/sharedTypes/gene/interfaces/gene.interface'

import './GeneListItem.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  isSelected?: boolean
  onSelectionChange: (value: boolean) => void
  gene: Gene
  disabled?: boolean
  loading?: boolean
  'data-test'?: string
}

const GeneListItem: React.FC<Props> = ({
  className,
  style,
  isSelected,
  onSelectionChange,
  gene,
  disabled,
  loading,
  'data-test': dataTest,
}) => {
  return (
    <li
      data-test={dataTest}
      className={`gene-list-item list-none relative ${
        disabled ? 'opacity-50 pointer-events-none' : ''
      }  ${className || ''}`}
      style={style}
    >
      <div className="absolute pt-4 pb-3 pl-2 pr-16 w-full flex flex-wrap">
        {loading ? (
          <RoundLoadingIndicator
            style={{ transform: 'translateX(-0.1rem)' }}
            className="mr-6 self-start w-5 h-5"
          />
        ) : (
          <RoundCheckbox
            className="mr-6"
            isChecked={isSelected}
            onChange={onSelectionChange}
          />
        )}
        <strong className="w-1/5 truncate">{gene.symbol}</strong>

        <span className="flex w-1/3">
          <span className="truncate">{gene.name}</span>
          <CopyToClipboard className="ml-1" textToCopy={gene.name} />
        </span>

        <span className="text-rich-black-light ml-auto truncate">
          {gene.organism}
        </span>
      </div>

      <Accordion title="" className="gene-list-item__accordion">
        <div className="w-4/5 pr-12 py-2 ml-auto flex justify-between items-end">
          <div>
            {gene.synonyms && (
              <>
                <div className="uppercase text-rich-black-lightest pb-1">
                  Synonyms
                </div>
                <ul className="">
                  {gene.synonyms.map((synonym, index, array) => (
                    <li className="inline font-black" key={synonym}>
                      {synonym}
                      {index === array.length - 1 ? '' : ', '}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <Theme themeName="richBlue" className="flex-shrink-0 ml-8">
            <a
              className="mb-1 inline-block "
              target="_blank"
              rel="noreferrer noopener"
              href={`https://www.ncbi.nlm.nih.gov/gene/${gene.ncbiId}`}
            >
              <Button size="small" className="toolbar__button">
                Go to NCBI
              </Button>
            </a>
          </Theme>
        </div>
      </Accordion>
    </li>
  )
}

export default GeneListItem
