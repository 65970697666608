import React from 'react'
import ChevronIconComponent from 'assets/svgs/chevron.svg'
import RoundLoadingIndicator from 'components/RoundLoadingIndicator/RoundLoadingIndicator'

import './CursorPagination.css'
import BackIconComponent from '../../assets/svgs/back.svg'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  style?: React.CSSProperties
  id?: string
  isPrevHidden?: boolean
  isPrevDisabled?: boolean
  isPrevLoading?: boolean
  isNextHidden?: boolean
  isNextDisabled?: boolean
  isNextLoading?: boolean
  onPrevClick: () => void
  onNextClick: () => void
}

const CursorPagination = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const cn = `cursor-pagination flex justify-between text-sm ${
      props.className || ''
    }`

    const ChevronIcon = ChevronIconComponent as any

    return (
      <div ref={ref} className={cn}>
        <button
          disabled={props.isPrevDisabled}
          className={`${
            props.isPrevDisabled ? 'opacity-50 cursor-default' : ''
          } ${
            props.isPrevHidden ? 'hidden' : ''
          } mr-auto group cursor-pagination__button font-bold text-vibrant-cyan inline-flex items-center hover:text-vibrant-cyan-dark active:text-vibrant-cyan-darker`}
          onClick={props.onPrevClick}
        >
          {props.isPrevLoading ? (
            <RoundLoadingIndicator
              className="ml-3 -mt-10 order-2"
              style={{ transform: 'translateY(1.28rem)' }}
            >
              Loading
            </RoundLoadingIndicator>
          ) : (
            ''
          )}
          <ChevronIcon className="text-vibrant-cyan stroke-current mx-2 group-hover:text-vibrant-cyan-dark" />
          Previous Page
        </button>
        <button
          disabled={props.isNextDisabled}
          className={`${
            props.isNextDisabled ? 'opacity-50 cursor-default' : ''
          } ${
            props.isNextHidden ? 'hidden' : ''
          } ml-auto group cursor-pagination__button font-bold text-vibrant-cyan inline-flex items-center hover:text-vibrant-cyan-dark active:text-vibrant-cyan-darker`}
          onClick={props.onNextClick}
        >
          {props.isNextLoading ? (
            <RoundLoadingIndicator
              className="mr-3 -mt-10"
              style={{ transform: 'translateY(1.28rem)' }}
            >
              Loading
            </RoundLoadingIndicator>
          ) : (
            ''
          )}
          Next Page
          <ChevronIcon className="text-vibrant-cyan stroke-current mx-2 group-hover:text-vibrant-cyan-dark" />
        </button>
      </div>
    )
  }
)

export default CursorPagination
