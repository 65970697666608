import React from 'react'
import { Link } from 'gatsby'
import { Button, Headline } from '@liquid-design/liquid-design-react'
import BackIconComponent from 'assets/svgs/back.svg'
import LogoComponent from 'assets/svgs/logo.svg'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  style?: React.CSSProperties
  id?: string
}

const NoPublication = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const cn = `flex flex-col items-center text-center w-full ${
    props.className || ''
  }`

  const BackIcon = BackIconComponent as any
  const Logo = LogoComponent as any

  return (
    <div ref={ref} className={cn}>
      <Logo className="mb-4" />
      <Headline type="H1" className="py-2 px-6">
        Publication not found.
      </Headline>
      <p className="font-bold text-rich-black-lighter mb-10">
        Please check your URL.
      </p>

      <Link to={'/database/'}>
        <Button size="big" className="mb-10">
          <BackIcon className="inline-block mr-4" />
          Go Back
        </Button>
      </Link>
    </div>
  )
})

export default NoPublication
