import React, { useEffect, useState } from 'react'
import {
  default as SortOptionComponent,
  SortValue,
} from 'components/SortOption/SortOption'
import { SortOption } from 'types/GeneList'
import RoundLoadingIndicator from '../../../components/RoundLoadingIndicator/RoundLoadingIndicator'
import RoundCheckbox from '../../../components/RoundCheckbox/RoundCheckbox'

interface Props {
  className?: string
  disabled?: boolean
  loading?: boolean
  isSelected?: boolean
  selectedSortOption: SortOption
  onChange: (value: SortOption) => void
  onSelectionChange: (isSelected: boolean) => void
}

const GeneListHeader: React.FC<Props> = ({
  className,
  disabled,
  loading,
  isSelected,
  selectedSortOption,
  onChange,
  onSelectionChange,
}) => {
  const cn = `gene-list-header flex items-center sm:justify-end ${
    className || ''
  }`

  const [titleSortOption, setTitleSortOption] = useState<SortValue>(
    SortValue.none
  )
  const [sourceSortOption, setSourceSortOption] = useState<SortValue>(
    SortValue.none
  )
  const [dateSortOption, setDateSortOption] = useState<SortValue>(
    SortValue.none
  )

  useEffect(() => {
    const getSortValue = (downOption: SortOption, upOption: SortOption) => {
      if (selectedSortOption === downOption) return SortValue.down
      if (selectedSortOption === upOption) return SortValue.up
      return SortValue.none
    }

    if (
      selectedSortOption === SortOption.bySymbolAZ ||
      selectedSortOption === SortOption.bySymbolZA
    ) {
      setTitleSortOption(
        getSortValue(SortOption.bySymbolAZ, SortOption.bySymbolZA)
      )
      setSourceSortOption(SortValue.none)
      setDateSortOption(SortValue.none)
    }
    if (
      selectedSortOption === SortOption.byNameAZ ||
      selectedSortOption === SortOption.byNameZA
    ) {
      setSourceSortOption(
        getSortValue(SortOption.byNameAZ, SortOption.byNameZA)
      )
      setTitleSortOption(SortValue.none)
      setDateSortOption(SortValue.none)
    }
    if (
      selectedSortOption === SortOption.byOrganismAZ ||
      selectedSortOption === SortOption.byOrganismZA
    ) {
      setDateSortOption(
        getSortValue(SortOption.byOrganismAZ, SortOption.byOrganismZA)
      )
      setTitleSortOption(SortValue.none)
      setSourceSortOption(SortValue.none)
    }
  }, [selectedSortOption])

  return (
    <div className={cn}>
      {loading ? (
        <RoundLoadingIndicator
          style={{ transform: 'translateX(-0.1rem)' }}
          className="ml-2 mr-6 self-start w-5 h-5"
        />
      ) : (
        <RoundCheckbox
          className="ml-2 mr-6"
          isChecked={isSelected}
          onChange={onSelectionChange}
        />
      )}

      <SortOptionComponent
        className="w-1/5"
        value={titleSortOption}
        onChange={(value) => {
          if (value === SortValue.down) {
            onChange(SortOption.bySymbolAZ)
          }
          if (value === SortValue.up) {
            onChange(SortOption.bySymbolZA)
          }
        }}
      >
        Symbol
      </SortOptionComponent>
      <SortOptionComponent
        className="w-1/3 pr-4 flex-grow"
        style={{ transform: 'translateX(-0.8rem)' }}
        value={sourceSortOption}
        onChange={(value) => {
          if (value === SortValue.down) {
            onChange(SortOption.byNameAZ)
          }
          if (value === SortValue.up) {
            onChange(SortOption.byNameZA)
          }
        }}
      >
        Name
      </SortOptionComponent>
      <SortOptionComponent
        className="mr-4"
        value={dateSortOption}
        onChange={(value) => {
          if (value === SortValue.down) {
            onChange(SortOption.byOrganismAZ)
          }
          if (value === SortValue.up) {
            onChange(SortOption.byOrganismZA)
          }
        }}
      >
        Organism
      </SortOptionComponent>
    </div>
  )
}

export default GeneListHeader
